import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

type Timer = {
  start: string | undefined
  seconds: number
}

export default function useTimer(intervalS = 1) {
  const [timer, setTimer] = useState<Timer>({ start: undefined, seconds: 0 })

  useEffect(() => {
    if (timer.start === undefined) return
    const startDate = dayjs(timer.start)

    //setInterval may behave incorrectly if used in a remote debugger (f.ex chrome. caused by system time differences). With a higher interval the behavior is almost correct
    if (global.location && global.location.pathname.includes('/debugger-ui')) {
      intervalS = 5
    }

    const intervalId = setInterval(() => {
      setTimer(prev => ({ ...prev, seconds: getSecondsDifference(startDate) }))
    }, 1000 * intervalS)

    return () => clearInterval(intervalId)
  }, [timer.start])

  function start(startDate?: string) {
    const stDate = startDate ?? dayjs().toISOString()
    setTimer({
      start: stDate,
      seconds: getSecondsDifference(dayjs(stDate)),
    })
  }

  function stop() {
    setTimer({ start: undefined, seconds: 0 })
  }

  function getSecondsDifference(startDate: dayjs.Dayjs) {
    return dayjs().diff(startDate, 'seconds')
  }

  return { seconds: timer.seconds, start, stop, isRunning: timer.start !== undefined }
}
