import { IM, IMStyle, SpacingProps } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'

import useTimer from '../hooks/useTimer'
import DateUtils from '../utils/DateUtils'
import TimeUtils from '../utils/TimeUtils'

export type TimerProps = {
  timeS: number
  runTimer?: boolean
  intervalS?: number
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  noSeconds?: boolean
}

export default function Timer({ timeS, runTimer, intervalS, spacing, style, textStyle, noSeconds }: TimerProps) {
  const { seconds: timerSeconds, start, stop, isRunning } = useTimer(intervalS)

  const seconds = isRunning ? timerSeconds : timeS

  useEffect(() => {
    if (!runTimer) {
      stop()
      return
    }

    start(dayjs().startOf('day').add(timeS, 'seconds').toISOString())
  }, [timeS, runTimer])

  return (
    <IM.View spacing={spacing} style={[styles.view, style]}>
      <IM.Text style={[styles.textHoursMinutes, textStyle]}>
        {DateUtils.formatDate(TimeUtils.revertSecondsToDate(seconds), seconds < 3600 && !noSeconds ? 'mm' : 'H:mm')}
      </IM.Text>
      {!noSeconds && (
        <IM.Text style={[styles.textSeconds, textStyle]}>{DateUtils.formatDate(TimeUtils.revertSecondsToDate(seconds % 60), ':ss')}</IM.Text>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
  },
  textHoursMinutes: {
    fontSize: IMStyle.typography.fontSizeBig,
    includeFontPadding: false,
  },
  textSeconds: {
    fontSize: IMStyle.typography.fontSizeBig,
    includeFontPadding: false,
  },
})
