import { IMLayout, useAlert, useEvent, useLanguage, useModalController, Utils } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import {
  Asset,
  BackendAsset,
  MEDIA_FOCUS_EFFECT_EVENT,
  MediaSortingMethod,
  mediaUtils,
  MediaView,
  MediaViewProps,
  useAsset,
} from '@infominds/react-native-media'
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Platform, StyleProp, useWindowDimensions, ViewStyle } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import { File, FolderRight } from '../../apis/types/apiResponseTypes'
import NoEntry from '../../components/NoEntry'
import {
  INFOBOX_FOLDER_EVENT_KEY,
  INFOBOX_NEW_INFO_EVENT_KEY,
  REFRESH_INFOBOX_EVENT_KEY,
  REFRESH_INFOBOX_FILE_COUNTER_EVENT_KEY,
} from '../../constants/EmitterKeys'
import UploadModal, { UploadModalData } from '../../modals/UploadModal'
import { InfoBoxStackParamList } from '../../navigation/type'
import { assetsToSyncAtom, mediaSortingMethodInfoboxAtom } from '../../utils/stateManager'
import appUtils from '../../utils/Utils'

let abortController: AbortController | undefined

export type InfoboxRefreshCounterEvent = { id: string; infoboxTyp: string }

export type InfoboxInfoEvent = {
  id: string
  infoboxTyp: string
  note: string | undefined
  filename: string | undefined
}

export interface InfoboxMediaViewImperativeMethods {
  handleUpload: () => void
  mediaAlert: () => void
}

interface Props extends Pick<MediaViewProps, 'contentContainerStyle'> {
  id: string
  infoboxType: string
  folderNumber: number | undefined
  folderRight: FolderRight | undefined
  buttonStyle?: StyleProp<ViewStyle>
}

const InfoBoxMediaView = (
  { id, infoboxType, folderNumber, folderRight, buttonStyle, contentContainerStyle }: Props,
  ref: ForwardedRef<InfoboxMediaViewImperativeMethods>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: () => {
      handleUpload()
    },
    mediaAlert: () => {
      alert(i18n.t('WARNING'), i18n.t('MEDIA_WARNING_MESSAGE'), [
        {
          text: i18n.t('DISCARD'),
          onPress: () => navigation.goBack(),
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          style: 'cancel',
        },
        { text: i18n.t('UPLOAD'), onPress: handleUpload, style: 'default' },
      ])
    },
  }))

  const { alert } = useAlert()
  // const { isSmallDevice } = useLayout()
  const { width } = useWindowDimensions()
  const { i18n, language } = useLanguage()
  const { assets: selectedAsset, set: setSelectedAsset } = useAsset()
  const { url, bearerToken, sessionKey } = useAuthentication()
  const navigation = useNavigation<NavigationProp<InfoBoxStackParamList>>()

  const sortingMethod = useRef<MediaSortingMethod>('reverse')
  const uploadModal = useModalController<UploadModalData>()

  const [loading, setLoading] = useState(true)
  const [assetUploaded, setAssetUploaded] = useState(0)
  const [assetToSync, setAssetsToSync] = useRecoilState(assetsToSyncAtom)
  const displayMethod = useRecoilValue(mediaSortingMethodInfoboxAtom(sessionKey))

  const { emit } = useEvent({ key: INFOBOX_FOLDER_EVENT_KEY })
  const { emit: refreshInfoboxCounter } = useEvent<InfoboxRefreshCounterEvent>({ key: REFRESH_INFOBOX_FILE_COUNTER_EVENT_KEY })
  const { emit: refreshAnimation } = useEvent({ key: MEDIA_FOCUS_EFFECT_EVENT })
  useEvent({ key: REFRESH_INFOBOX_EVENT_KEY }, () => {
    refresh()
  })
  useEvent<InfoboxInfoEvent>({ key: INFOBOX_NEW_INFO_EVENT_KEY }, data => {
    if (!(data.infoboxTyp === infoboxType)) return

    setSelectedAsset(prev => {
      prev.map(el => {
        if (el.filename === data.filename && el.id === data.id) {
          el.note = data.note
          return el
        }
        return el
      })

      return [...prev]
    })
  })

  useFocusEffect(
    useCallback(() => {
      refreshAnimation()
    }, [])
  )

  useEffect(() => {
    handleAssetsChange()
  }, [selectedAsset])

  useEffect(() => {
    refresh()

    return () => abortController && abortController.abort()
  }, [id, infoboxType, folderNumber])

  useEffect(() => {
    if (assetToSync !== 0 && assetUploaded === assetToSync) {
      reset()
      emit()
      refreshInfoboxCounter({ id: id, infoboxTyp: infoboxType })
      refresh()
      setTimeout(() => {
        uploadModal.close()
      }, 1000)
    }
  }, [assetUploaded, assetToSync])

  const refresh = () => {
    if (abortController !== undefined) {
      abortController.abort()
      abortController = undefined
    }

    setLoading(true)
    setSelectedAsset([])

    abortController = new AbortController()

    api
      .getInfoboxFiles({ id, infoboxTyp: infoboxType }, abortController)
      .then(file => createMediaAssets(file.filter(el => el.folderNumber === folderNumber)))
      .catch(err => {
        setLoading(false)
        abortController = undefined
        console.error(err)
      })
  }

  const createMediaAssets = (assets: File[]) => {
    const promises: Promise<Asset>[] = []

    mediaUtils.sortByDate(assets, sortingMethod.current).forEach(file => {
      if (selectedAsset.length !== 0 || selectedAsset.find(elem => elem.origin === 'backend' && elem.id === file.id) === undefined) {
        if (!!file.thumbImageAsPNG || file.thumbImageAsPNGError) {
          promises.push(
            BackendAsset.Create(
              'image',
              file.id,
              file.filename,
              undefined,
              file.thumbImageAsPNG,
              file.sizeKB * 1000,
              file.canDelete,
              new Date(file.date),
              file.note,
              file.thumbImageAsPNGError,
              false
            )
          )
        } else {
          promises.push(
            BackendAsset.Create(
              mediaUtils.getAssetType(file.extension ?? ''),
              file.id,
              file.filename,
              undefined,
              undefined,
              file.sizeKB * 1000,
              file.canDelete,
              new Date(file.date),
              file.note,
              false,
              false
            )
          )
        }
      }
    })

    Promise.all(promises)
      .then(setSelectedAsset)
      .catch(err => console.error('Can not create backend asset', err))
      .finally(() => {
        abortController = undefined
        setLoading(false)
      })
  }

  const handleAssetsChange = () => {
    setAssetUploaded(0)
    setAssetsToSync(mediaUtils.getAssetNumberToSync(selectedAsset))
  }

  const handleUpload = () => {
    console.debug(`Upload preparation result: files to sync ${assetToSync}`)
    upload()
  }

  const reset = () => {
    setAssetsToSync(0)
    setSelectedAsset([])
  }

  function upload() {
    uploadModal.show({ total: assetToSync })

    selectedAsset.forEach(asset => {
      if (asset.origin === 'backend' && asset.markedForRemoval) {
        api
          .deleteInfoboxFile(undefined, { id: asset.id, infoboxTyp: infoboxType })
          .catch(err => alert(i18n.t('ERROR'), Utils.stringValueReplacer(i18n.t('ERROR_DELETE_ASSET'), appUtils.getBackendErrorMessage(err))))
          .finally(() => setAssetUploaded(prev => prev + 1))
      }

      if (asset.origin !== 'backend') {
        asset
          .getBase64()
          .then(data => {
            if (typeof data === 'string') {
              folderNumber !== undefined &&
                api
                  .postInfoboxFile({
                    file: data,
                    infoboxTyp: infoboxType,
                    filename: asset.filename,
                    id,
                    note: asset.note,
                    foldernumber: folderNumber,
                  })
                  .catch(err => {
                    const message = appUtils.getBackendErrorMessage(err)
                    alert(i18n.t('ERROR'), Utils.stringValueReplacer(i18n.t('ERROR_CREATE_ASSET'), message))
                  })
                  .finally(() => setAssetUploaded(prev => prev + 1))
            }
          })
          .catch(err => {
            console.error(`Failed compressing asset ${asset.filename} -`, err)
            alert(i18n.t('ERROR'), Utils.stringValueReplacer(i18n.t('ASSET_COMPRESSION_ERROR'), asset.filename))
            setAssetUploaded(prev => prev + 1)
          })
      }
    })
  }

  if (!url || !bearerToken) return <></>

  if (folderNumber === undefined) return <NoEntry description={i18n.t('NO_FOLDER_SELECTED')} />

  return (
    <>
      <MediaView
        loading={loading}
        onMarkForRemoval={handleAssetsChange}
        language={language}
        displayMethod={displayMethod}
        sortingMethod={sortingMethod.current}
        folderRight={folderRight === FolderRight.read ? 'read' : 'write'}
        // buttonStyle={{
        //   backgroundColor: theme.header.main.background,
        //   iconColor: theme.header.main.text.primary,
        //   disabledBackgroundColor: theme.infobox.disableButtonBackground,
        //   disabledIconColor: theme.infobox.disableButtonIcon,
        // }}
        buttonContainerStyle={[{ padding: IMLayout.horizontalMargin }, buttonStyle]}
        onCameraPress={() => navigation.navigate('InfoboxNoBottomTabCamera')}
        onAssetPress={assetId =>
          navigation.navigate('InfoboxNoBottomTabAsset', {
            id: assetId,
            infoboxType,
          })
        }
        // onAssetInfo={assetId =>
        //   navigation.navigate('InfoboxNoBottomTabAssetInfo', {
        //     id: assetId,
        //     infoboxType,
        //     origin: selectedAsset.find(el => el.id === assetId)?.origin,
        //   })
        // }
        contentContainerStyle={
          contentContainerStyle ?? {
            paddingRight: Platform.OS === 'web' && selectedAsset.length !== 0 ? IMLayout.horizontalMargin * 2.5 : undefined,
            paddingHorizontal: IMLayout.horizontalMargin,
            paddingVertical: IMLayout.horizontalMargin - 4,
          }
        }
        estimatedItemSize={width}
        fetchUnmanagedAsset={{
          headers: { Authorization: bearerToken },
          url: assetId => {
            const completeUrl = `${url}/api/common/infoboxfileonly?infoboxTyp=${infoboxType}&infoboxFileId=${assetId}`
            return completeUrl
          },
        }}
      />
      <UploadModal controller={uploadModal} current={assetUploaded} />
    </>
  )
}

export default forwardRef(InfoBoxMediaView)
