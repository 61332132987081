import { IM, IMLayout, SpacingProps, useAlert, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator, Platform, Pressable, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import TimePicker from '../../components/Infominds/TimePicker'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import { useToast } from '../../contexts/ToastReferenceContext'
import useUserSettings from '../../hooks/useUserSettings'
import PresenceTimeEditDetailsModal, { PresenceTimeEditDetailModalControllerProps } from '../../modals/PresenceTimeEditDetailsModal'
import { PresenceTimeEntry, ThemeColorExpanded } from '../../types'
import PresenceTimeUtils from '../../utils/PresenceTimeUtils'
import TimeUtils from '../../utils/TimeUtils'
import { CalculateInitials } from '../../utils/Utils'
import PresenceTimeKeySubCard from './PresenceTimeKeySubCard'

interface ActivityCardProps {
  spacing?: SpacingProps
  presenceTimeKey: PresenceTimeEntry | undefined
  selected?: boolean
  editable?: boolean
}

export default function PresenceTimeKeyCard({ spacing, presenceTimeKey, selected }: ActivityCardProps) {
  const { theme, themeUtils } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const addTimePickerModal = useModalController<{ time: Date }>()
  const editDetailsModal = useModalController<PresenceTimeEditDetailModalControllerProps>()
  const presenceTime = usePresenceTime()
  const toast = useToast()
  const alert = useAlert()
  const [busy, setBusy] = useState(false)

  const headText = useMemo(() => CalculateInitials(presenceTimeKey?.key.code ?? '', Platform.OS === 'web' ? 2 : 3), [presenceTimeKey])
  const headBackground = useMemo(() => themeUtils.getRandomColorFromTheme(presenceTimeKey?.key.code ?? ''), [presenceTimeKey])
  const totalDuration = useMemo(() => Utils.sum(presenceTimeKey?.times, time => time.duration ?? 0) ?? 0, [presenceTimeKey])
  const durationText = useMemo(() => TimeUtils.formatDurationMinutes(TimeUtils.roundSecondsToMinutes(totalDuration)), [totalDuration])
  const isActiveTime = presenceTime.activeTime?.time.presenceTimeTypeId === presenceTimeKey?.key.id
  const isSelected = presenceTime.selectedKeyId === presenceTimeKey?.key.id || isActiveTime

  function handleCardPressed() {
    if (presenceTime.activeTime) {
      if (!isActiveTime) showTimeAlreadyStartedAlert()
      return
    }
    if (presenceTimeKey?.key.isInputStartStopActive) {
      if (presenceTime.dateMode) {
        editDetailsModal.show({ id: presenceTimeKey?.key.id ?? '' })
      } else if (!presenceTime.activeTime) {
        presenceTime.setSelectedKeyId(isSelected ? null : presenceTimeKey?.key?.id ?? null)
      }
    } else {
      showAddTimePickerModal()
    }
  }

  function showAddTimePickerModal() {
    let initialTime: Date
    // as suggestion calc remaining duration if possible
    if (totalDuration <= 0 && presenceTime.presenceTimeInfo?.minutesTarget) {
      const totalTime = Utils.sum(presenceTime.presenceTimeEntries, entry => Utils.sum(entry.times, time => time.duration ?? 0))
      initialTime = TimeUtils.revertMinutesToDate(
        Math.max(0, presenceTime.presenceTimeInfo?.minutesTarget - TimeUtils.roundSecondsToMinutes(totalTime))
      )
    } else {
      initialTime = TimeUtils.revertSecondsToDate(TimeUtils.roundSecondsToWholeMinute(totalDuration))
    }
    addTimePickerModal.show({ time: initialTime })
  }

  function onPresenceTimePress() {
    if (presenceTimeKey?.key.isInputStartStopActive) {
      editDetailsModal.show({ id: presenceTimeKey.key.id })
    } else {
      showAddTimePickerModal()
    }
  }

  function showTimeAlreadyStartedAlert() {
    toast.show(i18n.t('TimeAlreadyStarted'), { type: 'warning' })
  }

  function handleDurationInput(time: Date) {
    const time_Seconds = TimeUtils.getSecondsOfDay(time)
    if (presenceTimeKey?.key.isInputStartStopActive) {
      setBusy(true)
      const duration = TimeUtils.getSecondsOfDay(time)
      let endTime = TimeUtils.getSecondsOfDay(new Date())
      let startTime = endTime - duration
      if (startTime < 0) {
        startTime = 0
        endTime = duration
      }

      PresenceTimeUtils.createPresenceTime(
        userSettings?.employee.id ?? '',
        presenceTimeKey?.key.id ?? '',
        startTime,
        endTime,
        duration,
        presenceTime.date
      )
        .then(() => presenceTime.load())
        .catch(() => handleError('create'))
        .finally(() => {
          presenceTime.setSelectedKeyId(presenceTimeKey.key.id)
          setBusy(false)
        })
      return
    }
    if (time_Seconds < 60) {
      const detail = presenceTimeKey?.times.find(t => !!t.duration)?.details?.find(d => !!d.duration)
      if (!detail) return
      setBusy(true)
      api
        .deletePresenceTime(undefined, { id: detail.id })
        .then(() => presenceTime.load())
        .catch(() => handleError('delete'))
        .finally(() => setBusy(false))
      return
    }
    setBusy(true)
    PresenceTimeUtils.createPresenceTime(
      userSettings?.employee.id ?? '',
      presenceTimeKey?.key.id ?? '',
      0,
      0,
      TimeUtils.getSecondsOfDay(time),
      presenceTime.date
    )
      .then(() => presenceTime.load())
      .catch(() => handleError('create'))
      .finally(() => setBusy(false))
  }

  function handleError(type: 'create' | 'delete', message?: string) {
    if (!message) {
      switch (type) {
        case 'create':
          toast.show(i18n.t('ERROR_PRESENCE_TIME_CREATE'), { type: 'danger' })
          break
        case 'delete':
          toast.show(i18n.t('ERROR_PRESENCE_TIME_DELETE'), { type: 'danger' })
          break
      }
      return
    }
    alert.alert(i18n.t('ALERT_ERROR_TITLE'), message)
  }

  if (!presenceTimeKey) return <></>
  return (
    <IM.View spacing={spacing}>
      <IM.Card
        disabled={isActiveTime}
        head={{ text: headText, backGroundColor: headBackground }}
        defaultContent={{
          texts: [
            { text: presenceTimeKey.key.code, primary: true },
            !!presenceTimeKey.key.description && { text: presenceTimeKey.key.description, secondary: true },
          ],
        }}
        onPress={handleCardPressed}
        borderless={selected}
        endComponent={
          <Pressable
            style={[{ backgroundColor: theme.bar.background }, styles.endComponent]}
            disabled={!userSettings?.isPresenceTimeManualRecordingActive || !!presenceTime.activeTime}
            onPress={() => {
              onPresenceTimePress()
            }}>
            {busy && <ActivityIndicator size="small" color={theme.card.highlight} />}
            {!busy && (
              <IM.Text primary={totalDuration > 59} secondary={!totalDuration}>
                {durationText}
              </IM.Text>
            )}
          </Pressable>
        }
      />
      {isSelected && !presenceTime.dateMode && (
        <PresenceTimeKeySubCard presenceTimeKey={presenceTimeKey} showDetailEditModal={editDetailsModal.show} />
      )}
      <TimePicker controller={addTimePickerModal} setTime={handleDurationInput} />
      <PresenceTimeEditDetailsModal controller={editDetailsModal} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  endComponent: {
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    width: 60, // hardcoded for web (since primary text needs more space in web)
  },
})
