import { IM, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import type { Document } from '../apis/types/apiResponseTypes'
import { REFRESH_INFOBOX_FILE_COUNTER_EVENT_KEY } from '../constants/EmitterKeys'
import appUtils, { CalculateInitials } from '../utils/Utils'
import { InfoboxRefreshCounterEvent } from '../views/InfoBox/InfoBoxMediaView'

interface DocumentCardProps {
  document: Document
  onPress?: () => void
  selected?: boolean
  editable?: boolean
  fetchDocumentCount?: boolean
  borderless?: boolean
}

export default function DocumentCard({ document, onPress, selected, editable, fetchDocumentCount = false, borderless }: DocumentCardProps) {
  const { themeUtils, theme, colorScheme } = useTheme()
  const { i18n } = useLanguage()

  const headText = useMemo(() => CalculateInitials(document.code), [document])
  const headColor = useMemo(() => themeUtils.getRandomColorFromTheme(document.id), [document])
  const icon = appUtils.getDocumentIcon(document.documentType)

  const [files, setFiles] = useState<number | undefined>(undefined)

  useEvent<InfoboxRefreshCounterEvent>({ key: REFRESH_INFOBOX_FILE_COUNTER_EVENT_KEY }, ({ id, infoboxTyp }) => {
    updateFileCount(fetchDocumentCount, id, infoboxTyp)
  })

  useEffect(() => {
    updateFileCount(fetchDocumentCount, document.id, document.documentType)
  }, [fetchDocumentCount, document])

  const updateFileCount = (enabled: boolean, docId: string, docType: string) => {
    if (!enabled) return

    api
      .getInfoboxFiles({ id: docId, infoboxTyp: docType })
      .then(fetchedFiles => {
        setFiles(fetchedFiles.length)
      })
      .catch(err => {
        console.error(`Failed loading infobox files for docId ${document.id}:`, err)
      })
  }

  return (
    <IM.Card
      head={{ text: icon ? undefined : headText, backGroundColor: headColor, icon: icon }}
      defaultContent={{
        texts: [
          { text: document.code, primary: true },
          { text: document.description ?? '', secondary: true },
        ],
        endAdornment: {
          text: fetchDocumentCount ? (files === undefined ? i18n.t('LOADING_PLACEHOLDER') : files?.toString()) : undefined,
          icon: fetchDocumentCount && files !== undefined ? ['fal', 'photo-film-music'] : undefined,
          iconSize: 22,
          alignIcon: 'right',
          viewStyle: fetchDocumentCount && {
            backgroundColor: colorScheme === 'light' ? Color(theme.button.tertiaryButton).lighten(0.4).toString() : theme.button.tertiaryButton,
            borderRadius: 10,
            minHeight: 38,
            minWidth: 100,
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      }}
      onPress={onPress}
      buttons={editable ? { buttons: [{ icon: ['fal', 'pen-to-square'], backGroundColor: 'transparent', fontColor: theme.textDetail }] } : undefined}
      style={selected && [styles.selected, { borderColor: theme.primary }]}
      borderless={borderless}
    />
  )
}

const styles = StyleSheet.create({
  selected: {
    borderRightWidth: 4,
  },
})
