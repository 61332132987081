import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, NativeSyntheticEvent, Platform, StyleSheet, TextInput, TextInputEndEditingEventData } from 'react-native'

import 'react-time-picker/dist/TimePicker.css'

import useKeyPress from './hooks/useKeyPress'

interface Props {
  controller: ModalController<{ time: Date }>
  setTime: (time: Date) => void
}

export default function TimePicker(props: Props) {
  const { controller } = props

  return (
    <Modal visible={controller.isShown} onRequestClose={() => controller.close()} transparent>
      <Content {...props} />
    </Modal>
  )
}

function Content({ controller, setTime }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const [internalValue, setInternalValue] = useState<string>(convertDateToString(controller.data?.time))
  const [internalError, setInternalError] = useState(false)
  const inputRef = useRef<TextInput>(null)
  const borderColor = internalError ? theme.error : theme.input.border

  useEffect(() => {
    setInternalValue(convertDateToString(controller.data?.time))
  }, [controller.data?.time])

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 20)
  }, [])

  useKeyPress(
    {
      key: ['Enter'],
      cb: () => {
        handleAccept()
      },
    },
    [internalValue]
  )

  useKeyPress(
    {
      key: ['Esc'],
      cb: () => {
        handleAbort()
      },
    },
    [internalValue]
  )

  function handleAbort() {
    setInternalValue('')
    controller.close()
  }

  function validate() {
    if (internalValue && !isValidTime(internalValue)) {
      const finishedValue = formatTime(internalValue, true)
      if (isValidTime(finishedValue)) {
        setInternalValue(finishedValue)
        return true
      } else {
        setInternalError(true)
        return false
      }
    } else {
      setInternalError(false)
      return true
    }
  }

  const handleAccept = () => {
    if (!validate()) return

    let finishedValue = internalValue
    if (!isValidTime(finishedValue)) finishedValue = formatTime(internalValue, true)

    if (finishedValue !== '') {
      const [h, m] = finishedValue.split(':')
      const date = new Date()
      date.setHours(parseInt(h, 10))
      date.setMinutes(parseInt(m, 10))
      date.setSeconds(0)
      date.setMilliseconds(0)
      setTime(date)
    } else if (controller.data?.time) {
      setTime(controller.data.time)
    }
    setInternalValue('')
    controller.close()
  }

  const handleChangeText = (text: string) => {
    setInternalError(false)
    if (!isValidTime(text)) {
      if (text.length > internalValue.length) {
        if (text.length === 2 && text.match(/^\d{2}$/)) {
          text += ':'
        } else if (text.endsWith(':') && text.length === 2) {
          text = text.padStart(3, '0')
        }
      }
    }

    if (isValidTime(text)) {
      const newValue = formatTime(text, true)
      setInternalValue(newValue)
      return
    }
    setInternalValue(text)
  }

  function handleEndEditing(e: NativeSyntheticEvent<TextInputEndEditingEventData>) {
    if (!e.nativeEvent.text) {
      handleChangeText('')
      setInternalError(false)
      return
    }

    validate()
  }

  return (
    <IM.View style={styles.container}>
      <IM.View style={[{ backgroundColor: theme.modal.background, borderColor: theme.card.headBackground }, styles.modal]}>
        <IM.View spacing="bottom" style={styles.titleContainer}>
          <IM.Text style={styles.title}>{i18n.t('SELECT_TIME')}</IM.Text>
          <IM.View style={styles.divider} />
          <IM.PressableIcon icon={['far', 'times']} size={20} onPress={handleAbort} style={styles.icon} />
          {(internalValue || controller.data?.time) && (
            <IM.PressableIcon icon={['far', 'check']} size={20} onPress={handleAccept} style={styles.icon} disabled={internalError} />
          )}
        </IM.View>
        <IM.View
          style={[
            styles.textInputContainer,
            {
              backgroundColor: theme.input.background,
              borderColor: borderColor,
            },
          ]}
          spacingType="margin">
          <TextInput
            ref={inputRef}
            style={[styles.textInput, Platform.select({ web: styles.textInputWeb }), { color: theme.text }]}
            placeholderTextColor={theme.textPlaceholder}
            value={internalValue}
            onChangeText={handleChangeText}
            multiline={false}
            selectTextOnFocus
            numberOfLines={1}
            onBlur={e => {
              handleEndEditing(e)
            }}
            onSubmitEditing={handleAccept}
          />
        </IM.View>
      </IM.View>
    </IM.View>
  )
}

function isValidTime(text: string | undefined) {
  return !!text?.match(/^\d{2}:\d{2}/)
}

function formatTime(time: string, limitTo24h: boolean) {
  let [hh = 0, mm = 0] = time.split(':').map(Number)
  if (mm > 59) mm = 59
  if (limitTo24h && hh > 23) {
    hh = 23
    mm = 59
  }

  const finishedValue = `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`
  return finishedValue
}

function convertDateToString(date: Date | undefined) {
  if (!date) return ''
  const hh = date.getHours().toString().padStart(2, '0')
  const mm = date.getMinutes().toString().padStart(2, '0')
  return `${hh}:${mm}`
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000050',
  },
  textInputContainer: {
    borderWidth: 1,
    borderRadius: IMStyle.layout.borderRadius,
    flexDirection: 'row',
    ...Platform.select({
      web: {
        outlineStyle: 'none',
      },
    }),
  },
  modal: {
    minWidth: 250,
    justifyContent: 'center',
    borderRadius: IMStyle.layout.borderRadius,
    shadowColor: '#0e1216',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 20,
    padding: 25,
    borderWidth: 0,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: { flexDirection: 'row', flex: 1 },
  icon: {
    marginTop: -8,
    marginRight: -8,
  },
  divider: {
    flexGrow: 1,
  },
  textInput: {
    padding: 12,
    flex: 1,
  },
  textInputWeb: {
    //@ts-ignore only for web
    outlineStyle: 'none', //web
  },
})
