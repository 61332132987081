import { isEqual } from 'date-fns'
import React, { Dispatch, SetStateAction } from 'react'
import { DayPicker } from 'react-day-picker'
import { StyleSheet, useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'

import { UpdateSources } from './context/CalendarContext'
import useCalendar from './hooks/useCalendar'

import 'react-day-picker/dist/style.css'

import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import Color from 'color'

import appUtils from '../../../utils/Utils'

interface Props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const css = `
  .my-selected:not([disabled]) { 
    background-color: ${IMStyle.palette.tint};
    color: ${IMStyle.palette.white}
  }
  .my-selected:hover:not([disabled]) { 
    background-color: ${Color(IMStyle.palette.tint).alpha(0.5).toString()};
  }
  .my-button:hover:not([disabled]) { 
    background-color: ${Color(IMStyle.palette.tint).alpha(0.5).toString()};
  }
  .my-today { 
    color: ${IMStyle.palette.tint};
  }
`
export default function DatePicker({ setShow, show }: Props) {
  const { date, setDate } = useCalendar()
  const { language } = useLanguage()
  const { width, height } = useWindowDimensions()
  const { theme } = useTheme()

  const onChange = (newDate: Date | undefined) => {
    if (newDate && !isEqual(date, newDate)) {
      setDate(newDate, UpdateSources.PICKER_SELECT)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal isVisible={show} onBackdropPress={handleClose} backdropOpacity={0.4} style={styles.modal} deviceHeight={height} deviceWidth={width}>
      <IM.View style={[styles.modalView, { backgroundColor: theme.modal.background }]}>
        <style>{css}</style>
        <DayPicker
          mode="single"
          selected={date}
          onSelect={onChange}
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today',
          }}
          style={styles.font}
          locale={appUtils.languageToLocale(language)}
        />
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  font: {
    fontFamily: 'Roboto',
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 370,
    alignSelf: 'center',
  },
})
