import { useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../../types'

export default function InitMeta() {
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  return (
    <>
      <meta name="theme-color" content={colorScheme === 'dark' ? theme.background : theme.modal.headBackground} />
      {/* <meta name="background_color" content={theme.background} /> */}
    </>
  )
}
